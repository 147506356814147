import * as React from 'react';
import s from './SideFilters.module.scss';
import { Area } from './Filters/Area';
import { AuctionType } from './Filters/AuctionType';
import { FormType } from './Filters/FormType';
import { Organizer } from './Filters/Organizer';
import { Price } from './Filters/Price';
import { Status } from './Filters/Status';
import { ActionButtons } from './ActionButtons';
import { RobotLotAction } from './RobotAction/RobotAction';
import { Geography } from './Filters/Geography';

type SideFiltersProps = {
  onFiltersClose: VoidFunction;
  className?: string;
};

const SideFilters = ({ onFiltersClose, className = '' }: SideFiltersProps): React.ReactElement<SideFiltersProps> => {
  return (
    <div className={s.filtersWrap}>
      <div className={`${s.filtersGrid} ${className}`}>
        <AuctionType />
        <Area />
        <Price />
        <Status />
        <FormType />
        <Organizer />
        <Geography placeholder="Регион" />
      </div>
      <div className={s.actionGrid}>
        <RobotLotAction />
        <div className={s.empty}></div>
        <ActionButtons onFiltersClose={onFiltersClose} className={s.action__buttons__wrap} />
      </div>
    </div>
  );
};

export default SideFilters;
