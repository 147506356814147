import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BaseInput } from 'components/BaseInput';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import Select from 'components/Select/Select';

type ObjectTypeProps = {
  className?: string;
};

const AggregatedType = ({ className }: ObjectTypeProps): React.ReactElement<ObjectTypeProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput className={className} title="Тип">
      <Select
        options={filters.aggregatedType.options}
        value={filters.aggregatedType.selectedValue.value}
        onChange={(item) => filters.aggregatedType.selectedValue.change(item || 'all')}
        isOptionsLoading={filters.loadingStage.isLoading}
        withSearch={false}
        dropdownStyle={{
          zIndex: 1300,
        }}
      />
    </BaseInput>
  );
};

export default observer(AggregatedType);
