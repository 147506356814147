import { FilterOption, FloorOptions } from 'entities/lotFilters';
import { FloorOptionsType } from 'stores/LotListCatalogStore/LotListCatalogFiltersModel/LotListCatalogFiltersModel';

export const filterSerialize = (
  items: {
    [key: string]: string | number | object | null | { from: unknown; to: unknown; options?: string[] } | boolean;
  },
  floorOptions?: FloorOptionsType,
) => {
  const _filters = { ...items };
  console.log('_filters', _filters);
  Object.keys(_filters).forEach((key) => {
    if (_filters[key] === null) {
      delete _filters[key];
    } else if (Array.isArray(_filters[key]) && _filters[key].length === 0) {
      delete _filters[key];
    }

    if (typeof _filters[key] === 'object' && _filters[key] && 'from' in _filters[key] && 'to' in _filters[key]) {
      if (_filters[key].to === null) {
        delete _filters[key].to;
      }
      if (_filters[key].from === null) {
        delete _filters[key].from;
      }
    }
  });

  if ('cadastral_number' in _filters && _filters['cadastral_number'] === '') {
    delete _filters['cadastral_number'];
  }

  if ('floor' in _filters && floorOptions) {
    const _floorOptions: string[] = [];
    Object.keys(floorOptions).forEach((key) => {
      if (floorOptions[key as FloorOptions] === true) {
        _floorOptions.push(key);
      }
    });

    if (_floorOptions.length > 0) {
      (_filters.floor as any)['options'] = floorOptions;
    }
  }

  return _filters;
};

export const searchFilterByTitle = (value: string, options: FilterOption[]) => {
  const treeCity = [...options];
  const result: FilterOption[] = [];

  const filterByTitle = (values: FilterOption[]) => {
    const resultChild: FilterOption[] = [];
    values.forEach((item) => {
      if (item.title.toUpperCase().includes(value.toUpperCase()) && item.children) {
        const items = filterByTitle(item.children);
        resultChild.push({ ...item, children: items.length > 0 ? items : [] });
      } else if (!item.title.toUpperCase().includes(value.toUpperCase()) && item.children) {
        const items = filterByTitle(item.children);
        if (items.length > 0) {
          resultChild.push({ ...item, children: items });
        }
      } else if (item.title.toUpperCase().includes(value.toUpperCase()) && item.children === undefined) {
        resultChild.push({ ...item, children: [] });
      }
    });

    return resultChild;
  };

  treeCity.forEach((item) => {
    if (item.title.toUpperCase().includes(value.toUpperCase()) && item.children) {
      const items = filterByTitle(item.children);
      result.push({ ...item, children: items.length > 0 ? items : [] });
    } else if (!item.title.toUpperCase().includes(value.toUpperCase()) && item.children) {
      const items = filterByTitle(item.children);
      if (items.length > 0) {
        result.push({ ...item, children: items });
      }
    } else if (item.title.toUpperCase().includes(value.toUpperCase()) && item.children === undefined) {
      result.push({ ...item, children: undefined });
    }
  });

  return result;
};

export function numberDeclination(value: number, words: [string, string, string]) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}
